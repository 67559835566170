.loaderActive {
  top: 0;
  left: 0;
  gap: 2rem;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.loaderHidden {
  display: none;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(25 39 53 / 1);
}

.image {
  height: 150px;
  width: 150px;
}

.pulse {
  animation: pulsate 0.5s ease-in-out infinite alternate;
}

@keyframes pulsate {
  from {
    transform: scale(2);
  }
  to {
    transform: scale(2.5);
  }
}

.loadingMsg {
  z-index: 999;
  color: white;
  margin-top: 1rem;
  font-size: 1.5rem;
}