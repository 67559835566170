.w-nav-link-button {
  color: white;
  margin: 8px 10px;
}

.w-button {
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdown-toggle-2.w-dropdown-toggle:hover .icon.w-icon-dropdown-toggle {
  color: #39d6ff;
}

.dropdown-toggle-2.w-dropdown-toggle:hover .text-block-12 {
  color: #39d6ff;
}